import React, { useEffect } from 'react';

interface Redirect {
  url: string;
  method: string;
  preconditions?: {
    method: string;
    url: string;
    parameters: { name: string; value: string }[];
  }[];
}

interface Parameter {
  name: string;
  value: string;
}

interface Props {
  redirect: Redirect;
}

const RedirectNomupayHandler: React.FC<Props> = ({ redirect }) => {
  useEffect(() => {
    const handleRedirect = (redirect: Redirect) => {
      if (redirect?.preconditions && redirect?.preconditions.length > 0) {
        // Loop through preconditions if necessary
        for (const precondition of redirect.preconditions) {
          const preconditionIframe = document.createElement('iframe');
          preconditionIframe.style.display = 'none';
          document.body.appendChild(preconditionIframe);

          const preconditionIframeDoc = preconditionIframe.contentDocument || preconditionIframe.contentWindow?.document;
          if (!preconditionIframeDoc) {
            console.error("Iframe contentWindow or contentDocument is null.");
            return;
          }

          const preconditionForm = preconditionIframeDoc.createElement('form');
          preconditionForm.method = precondition.method;
          preconditionForm.action = precondition.url;

          // Add hidden inputs for precondition parameters
          precondition.parameters.forEach((param: Parameter) => {
            const input = preconditionIframeDoc.createElement('input');
            input.type = 'hidden';
            input.name = param.name;
            input.value = param.value;
            preconditionForm.appendChild(input);
          });

          preconditionIframeDoc.body.appendChild(preconditionForm);
          preconditionForm.submit();

          // On iframe load, handle the next redirect step
          preconditionIframe.onload = () => {
            preconditionIframe.parentNode?.removeChild(preconditionIframe);
            handleRedirectAfterPrecondition(redirect);
          };
        }
      } else {
        // If no preconditions, redirect immediately
        return null;
      }
    };

    const handleRedirectAfterPrecondition = (redirect: Redirect) => {
      const redirectIframe = document.createElement('iframe');
      redirectIframe.style.display = 'block';
      redirectIframe.style.width = '100vw'; // Use viewport width for full coverage
      redirectIframe.style.height = '100vh'; // Use viewport height for full coverage
    
  
      document.body.appendChild(redirectIframe);

      const redirectIframeDoc = redirectIframe.contentDocument || redirectIframe.contentWindow?.document;
      if (!redirectIframeDoc) {
        console.error("Iframe contentWindow or contentDocument is null.");
        return;
      }
      const redirectForm: any = redirectIframeDoc.createElement('form');
      redirectForm.method =  'POST'; 
      redirectForm.action = redirect.url;
      redirectForm.target = "_self"; // Set target
      
      // Add hidden inputs if there are precondition parameters
      if (redirect?.preconditions && redirect?.preconditions.length > 0) {
        redirect.preconditions[0].parameters.forEach((param: Parameter) => {
          const input = redirectIframeDoc.createElement('input');
          input.type = 'hidden';
          input.name = param.name;
          input.value = param.value;
          redirectForm.appendChild(input);
        });
      }
      redirectIframeDoc.body.appendChild(redirectForm);
      redirectForm.submit();
      // Clean up the iframe after submission
      // redirectIframe.onload = () => {
      //   redirectIframe.parentNode?.removeChild(redirectIframe);
      // };
    };

    handleRedirect(redirect);
  }, [redirect]);

  return null;
};

export default RedirectNomupayHandler;

