// export const TIMEOUT_INTERVALS={
//     SUCCESS: 10000,
//     FAIL:10000
// }

export const REDIRECT_COUNT = {
    SUCCESS:30,
    FAIL:30,
    CANCEL:30,
}
export const REDIRECT_COUNT_FOR_IBEC = {
    SUCCESS:100,
    FAIL:100
}